// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-chapters-jsx": () => import("./../../../src/pages/chapters.jsx" /* webpackChunkName: "component---src-pages-chapters-jsx" */),
  "component---src-pages-competition-jsx": () => import("./../../../src/pages/competition.jsx" /* webpackChunkName: "component---src-pages-competition-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-results-jsx": () => import("./../../../src/pages/results.jsx" /* webpackChunkName: "component---src-pages-results-jsx" */)
}

